<template>
    <div class="TopUp">
		<div class="name">提现</div>
		<div class="title">银行</div>
		<el-select v-model="value" placeholder="请选择银行" style="width:100%">
		<el-option
			v-for="item in options"
			:key="item.value"
			:label="item.label"
			:value="item.value"
		/>
		</el-select>
		<div class="title">银行卡号</div>
		<el-input v-model="input" type="number" placeholder="请输入银行卡号" />
		<div class="title">金额</div>
		<el-input v-model="input" type="number" placeholder="请输入金额" />
		<div class="title">最近提现</div>
		<el-table :data="tableData" border style="width: 100%;height:500px;">
			<el-table-column prop="date" label="银行" />
			<el-table-column prop="name" label="银行卡号" />
			<el-table-column prop="address" label="金额" width="180" />
		</el-table>
    </div>
</template>
<script>
export default {
	data() {
		return{
			options:[
			  {
			    value: 'Option1',
			    label: 'Option1',
			  },
			  {
			    value: 'Option2',
			    label: 'Option2',
			  },
			],
			tableData:[
			  {
			    date: '中国银行',
			    name: '1234567890',
			    address: '300',
			  },
			  {
			    date: '工商银行',
			    name: '1234567890',
			    address: '500',
			  },
			  {
			    date: '建设银行',
			    name: '1234567890',
			    address: '1000',
			  },
			  {
			    date: '农业银行',
			    name: '1234567890',
			    address: '2000',
			  },
			]
		}
	},
	created(){

	},
	methods:{

	}
};
</script>
<style scoped>
	.TopUp{
		height:100%;
		padding:20px;
	}
	.name{
		font-size:20px;
		font-weight:bold;
		margin-top:20px;
	}
	.title{
		font-size: 16px;
		color: #999;
		margin-bottom:10px;
		margin-top:40px;
	}
</style>
